import React, { useEffect } from 'react';
import { Provider, useDispatch } from 'react-redux';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from 'react-router-dom';

import {
  ThemeProvider,
  createMuiTheme,
  CircularProgress,
} from '@material-ui/core';

import { signInSuccess } from './slices/authSlice';
import store from './store';
import feathers from './feathers';

import './styles/styles.scss';

import Home from './pages/Home/Home';
import InstantPrice from './pages/InstantPrice/InstantPrice';
import SignIn from './pages/SignIn/SignIn';
import CreateAccount from './pages/CreateAccount/CreateAccount';
import Price from './pages/Quote/Quote';

import Navigation from './components/Navigation/Navigation';

let theme = createMuiTheme({
  palette: {
    primary: {
      500: '#27ae60',
    },
  },
});

const PrivateRoute = ({ component, ...options }: any) => {
  const [reAuthenticateWorking, setReAuthenticateWorking] =
    React.useState(true);

  const [loggedIn, setLoggedIn] = React.useState(false);

  useEffect(() => {
    feathers
      .reAuthenticate()
      .then((res) => {
        setLoggedIn(true);
      })
      .catch((err) => {
        console.log('Err');
        console.log(err);
        if (err.name === 'NotAuthenticated') {
          setLoggedIn(false);
        }
      })
      .finally(() => {
        setReAuthenticateWorking(false);
      });
  }, []);

  if (reAuthenticateWorking) {
    return <CircularProgress size="large" />;
  }

  if (!reAuthenticateWorking && loggedIn) {
    return <Route {...options} component={component} />;
  }

  if (!reAuthenticateWorking && !loggedIn) {
    return <Redirect to="/sign-in" />;
  }

  return null;
};

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    feathers
      .reAuthenticate()
      .then((res) => {
        dispatch(signInSuccess(res));
      })
      .catch((e) => {});
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Navigation />
        <main className="main-content">
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/instant-price" exact component={InstantPrice} />
            <Route path="/create-account/:id" exact component={CreateAccount} />
            <Route path="/sign-in" exact component={SignIn} />

            {/*<Route path="/forgot-password" exact component={ForgotPassword} /> */}
            {/* //@ts-ignore */}
            <PrivateRoute path="/instant-price/:id" exact component={Price} />
          </Switch>
        </main>
      </Router>
    </ThemeProvider>
  );
}

export default () => (
  <Provider store={store}>
    <App />
  </Provider>
);
