import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { motion } from 'framer-motion';
import { Box, Container, Grid } from '@material-ui/core';

import CardRadioInput from '../../components/CardRadioInput/CardRadioInput';

import FAQList from '../../components/FAQList/FAQList';

import { setServiceType, setTotalSteps } from '../../slices/instantPriceSlice';

import { ReactComponent as SodIllustration } from '../../assets/images/Sod illustration.svg';
import { ReactComponent as CustomPavingIllustration } from '../../assets/images/Custom paving.svg';
import { ReactComponent as LawnIrrigation } from '../../assets/images/Lawn irrigation.svg';

import goNext from './goNext';
import Footer from '../../components/Footer/Footer';

export default (props: any) => {
  const dispatch = useDispatch();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Box>
        <Grid container justify="center">
          <Grid item xs={12} md={10}>
            <h1 className="step-title title text-center">
              Start your free instant price.
            </h1>
          </Grid>
          <Grid item xs={12} md={8}>
            <p className="step-subtitle subtitle subtitle--medium text-center">
              Start by selecting a service and answer a few questions about your
              landscaping goals to receive a free, all-inclusive estimate.
            </p>
          </Grid>
        </Grid>

        <div className="fancy-input-container">
          <CardRadioInput
            title="Sod installation"
            imgSrc={SodIllustration}
            imgIsSvg
            fillFull
            selected={props.formik.values.serviceType === 'sodInstallation'}
            onClick={() => {
              props.formik.setFieldValue('serviceType', 'sodInstallation');
              dispatch(setServiceType('sodInstallation'));
              goNext();
              dispatch(setTotalSteps(4));
            }}
          />

          <CardRadioInput
            title="Custom paving"
            imgSrc={CustomPavingIllustration}
            imgIsSvg
            fillFull
            selected={props.formik.values.serviceType === 'customPaving'}
            onClick={() => {
              props.formik.setFieldValue('serviceType', 'customPaving');
              dispatch(setServiceType('customPaving'));
              goNext();
              dispatch(setTotalSteps(2));
            }}
          />

          <CardRadioInput
            title="Lawn irrigation"
            imgSrc={LawnIrrigation}
            imgIsSvg
            fillFull
            selected={props.formik.values.serviceType === 'lawnIrrigation'}
            onClick={() => {
              props.formik.setFieldValue('serviceType', 'lawnIrrigation');
              dispatch(setServiceType('lawnIrrigation'));
              goNext();
              dispatch(setTotalSteps(2));
            }}
          />
        </div>

        <Container maxWidth="lg" style={{ marginTop: '150px' }}>
          <Grid container justify="center" alignItems="center" spacing={4}>
            <Grid item xs={12} md={7}>
              <h1 className="title text-center no-margin">FAQs</h1>
            </Grid>
          </Grid>
        </Container>

        <Container maxWidth="lg">
          <Grid container justify="center" alignItems="center" spacing={4}>
            <Grid item xs={12} md={8}>
              <FAQList />
            </Grid>
          </Grid>
        </Container>

        <Footer />
      </Box>
    </motion.div>
  );
};
