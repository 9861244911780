import { Container, Grid, Button, Box } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { ReactComponent as SodIllustration } from "../../assets/images/Sod illustration.svg";
import { ReactComponent as CustomPavingIllustration } from "../../assets/images/Custom paving.svg";
import { ReactComponent as LawnIrrigation } from "../../assets/images/Lawn irrigation.svg";
import HomestarsLogo from "../../assets/images/lawn-doctors-certifications-homestars.svg";
import GoogleLogo from "../../assets/images/Google.svg";
import GuaranteeIcon from "../../assets/images/Guarantee Icon.svg";
import GreenThumb from "../../assets/images/Green Thumb.svg";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import StarIcon from "@material-ui/icons/Star";
import CallIcon from "@material-ui/icons/Call";
import ForumIcon from "@material-ui/icons/Forum";

import mobileIntroImage from "../../assets/images/lawn-doctors-home-intro-mobile.jpg";

import reviewLandscapeGrandma from "../../assets//images/lawn-doctors-reviews-background.jpg";
import reviewPortraitGrandma from "../../assets//images/lawn-doctors-reviews-background--mobile.jpg";

import step1HowWeWork from "../../assets/images/lawn-doctors-step-1.jpg";
import step2HowWeWork from "../../assets/images/lawn-doctors-step-2.jpg";
import step3HowWeWork from "../../assets/images/lawn-doctors-step-3.jpg";

import "./Home.scss";

import FAQList from "../../components/FAQList/FAQList";
import Footer from "../../components/Footer/Footer";

export default (props: any) => {
  const history = useHistory();

  return (
    <>
      <Container
        className="home-container"
        maxWidth={false}
        style={{ maxWidth: "1440px" }}
      >
        <div className="home-intro">
          <div className="home-intro__text">
            <div className="home-intro__text__overflow"></div>
            <h1>The grass is always greener with a Lawn Doctor.</h1>
            <p className="subtitle">
              We provide comprehensive landscape services, like sod
              installation, custom paving for interlocking brick and stone, and
              lawn irrigation solutions.
            </p>
            <Button
              onClick={() => props.history.push("/instant-price")}
              variant="contained"
              className="btn btn-lg btn-primary"
            >
              Start a Free Instant Quote
            </Button>
            <Box className="cta-bottom">
              <p className="cta-bottom__heading">
                Call or chat with a lawn doctor
              </p>

              <Box className="cta-bottom__links">
                <a href="tel:6134001265">
                  <CallIcon
                    style={{
                      marginRight: "8px",
                    }}
                  />
                  <span className="text">(613) 400-1265</span>
                </a>

                <a href="tel:6134001265">
                  <ForumIcon
                    style={{
                      marginRight: "8px",
                    }}
                  />
                  <span className="text">LIVE CHAT</span>
                </a>
              </Box>
            </Box>
          </div>
          <div className="home-intro__image"></div>
        </div>
      </Container>
      <div className="home-intro__image--mobile-container">
        <img
          className="home-intro__image--mobile img-responsive"
          src={mobileIntroImage}
          alt=""
        />
      </div>

      <section className="section section--locally-owned">
        <Container maxWidth="lg">
          <Grid container justify="center" alignItems="center" spacing={4}>
            <Grid item xs={12} md={7}>
              <h1 className="title text-center xs-text-left">
                Locally-owned in Ottawa since 2016.
              </h1>
            </Grid>
          </Grid>

          <div className="certifications-area">
            <Grid container justify="center" alignItems="center" spacing={4}>
              {/* <Grid item md={3} sm={6} xs={6}>
        <Box className="certification-item certification-item--bbb">
          <img src={BBBLogo} alt="BBB" className="bbb-icon" />
          <p></p>

          <h4>Ottawa landscaping professionals</h4>
        </Box>
      </Grid> */}
              <Grid item md={3} sm={6} xs={6}>
                <Box className="certification-item certification-item--homestars">
                  <img
                    src={HomestarsLogo}
                    alt="Homestars"
                    className="homestars-icon"
                  />
                  <p></p>
                  <h4>Strongly recommended & highly rated</h4>
                </Box>
              </Grid>
              <Grid item md={3} sm={6} xs={6}>
                <Box className="certification-item">
                  <Box>
                    <img
                      src={GoogleLogo}
                      alt="Google"
                      className="google-icon"
                    />
                    <Box mt={1}>
                      <StarIcon />
                      <StarIcon />
                      <StarIcon />
                      <StarIcon />
                      <StarIcon />
                    </Box>
                  </Box>
                  <p></p>

                  <h4>Rated 5/5 stars</h4>
                </Box>
              </Grid>
              <Grid item md={3} sm={6} xs={6}>
                <Box className="certification-item">
                  <img
                    src={GuaranteeIcon}
                    alt="Guarantee"
                    className="guarantee-icon"
                  />
                  <p></p>

                  <h4>Healthy Lawn Guarantee™</h4>
                </Box>
              </Grid>
            </Grid>
          </div>
        </Container>
      </section>

      <section className="section section--services">
        <Container maxWidth="lg">
          <Grid container justify="center" alignItems="center" spacing={4}>
            <Grid item xs={12} md={7}>
              <h1 className="title text-center xs-text-left no-margin">
                Landscaping your lawn can be this easy.
              </h1>
              <p
                className="subtitle text-center xs-text-left"
                style={{ marginTop: "1em" }}
              >
                Schedule a call with a Lawn Doctor or start a free instant
                quote. Answer a few questions about your landscaping goals and
                receive a free, all-inclusive estimate. There is no obligation.
              </p>
            </Grid>
          </Grid>
          <section className="section section--custom">

              <Grid container spacing={4} justify="center">
                <Grid item xs={12}>
                  <Button
                    onClick={() => props.history.push("/instant-price")}
                    variant="contained"
                    className="btn btn-lg btn-primary"
                  >
                    Start a Free Instant Quote
                  </Button>


                  
                  <Box className="cta-bottom cta-bottom--big-area">
                    <p className="cta-bottom__heading">
                      Call or chat with a lawn doctor
                    </p>
                    <Box className="cta-bottom__links">
                      <a href="tel:6134001265">
                        <CallIcon
                          style={{
                            marginRight: "8px",
                          }}
                        />
                        <span className="text">(613) 400-1265</span>
                      </a>
                      <a href="tel:6134001265">
                        <ForumIcon
                          style={{
                            marginRight: "8px",
                          }}
                        />
                        <span className="text">LIVE CHAT</span>
                      </a>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
   
          </section>
        </Container>

        <Container maxWidth="lg" className="service-item service-item--one">
          <Grid
            container
            justify="space-around"
            alignItems="center"
            spacing={4}
          >
            <Grid item xs={12} md={6} sm={6}>
              <img src={step1HowWeWork} className="img-responsive" alt="" />
            </Grid>
            <Grid item xs={12} md={5} sm={6}>
              <h1 className="title title--medium no-margin color-secondary">
                <span className="color-primary">
                  Tell us what landscaping services you need.
                </span>{" "}
                We can help you instantly calculate your cost in less than two
                minutes.
              </h1>
            </Grid>
          </Grid>
        </Container>

        <div className="divider divider--services"></div>

        <Container maxWidth="lg" className="service-item service-item--two">
          <Grid
            container
            justify="space-around"
            alignItems="center"
            spacing={4}
          >
            <Grid item xs={12} sm={6} md={5}>
              <h1 className="title title--medium no-margin color-secondary">
                <span className="color-primary">
                  Review and accept your free, all-inclusive estimate.
                </span>{" "}
                A dedicated Lawn Doctor will meet with you to confirm your
                estimate and walk you through our process.
              </h1>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <img src={step2HowWeWork} className="img-responsive" alt="" />
            </Grid>
          </Grid>
        </Container>

        <div className="divider divider--services"></div>

        <Container maxWidth="lg" className="service-item service-item--three">
          <Grid
            container
            justify="space-around"
            alignItems="center"
            spacing={4}
          >
            <Grid item xs={12} sm={6} md={6}>
              <img src={step3HowWeWork} className="img-responsive" alt="" />
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <h1 className="title title--medium no-margin color-secondary">
                <span className="color-primary">
                  Your Lawn Doctor professionals take care of the rest.
                </span>{" "}
                Our fully licensed and reliable team will finish your project
                on-time and on-budget.
              </h1>
            </Grid>
          </Grid>
        </Container>
      </section>

      <section className="section">
        <Container maxWidth="lg" className="section--services-list">
          <Grid container justify="center" alignItems="center" spacing={4}>
            <Grid item xs={12} md={7}>
              <h1 className="title text-center xs-text-left">Our services</h1>

              <p
                className="subtitle text-center xs-text-left"
                style={{ marginTop: "1em" }}
              >
                We provide comprehensive landscape services, like sod
                installation, custom interlocking brick and stone, and custom
                lawn irrigation solutions.
              </p>
            </Grid>
          </Grid>

          <Grid
            container
            className="services-list-container"
          // spacing={6}
          >
            <div className="services-list-item">
              <SodIllustration />

              <div>
                <h1 className="title--medium color-primary">
                  Sod installation
                </h1>
                <p className="subtitle">
                  We source Kentucky blue grass from local Ottawa farms, cut
                  fresh every morning, and installed the same day.
                </p>
              </div>

              <p></p>
            </div>
            <div className="services-list-item">
              <CustomPavingIllustration />
              <p></p>

              <div>
                <h1 className="title--medium color-primary no-margin">
                  Custom paving
                </h1>
                <p className="subtitle">
                  Add lasting durability and value to your home with custom
                  interlocking brick and stone for a walkway, patio, driveway,
                  or pool deck.
                </p>
              </div>
            </div>

            <div className="services-list-item">
              <LawnIrrigation />
              <p></p>

              <div>
                <h1 className="title--medium color-primary">Lawn irrigation</h1>
                <p className="subtitle">
                  Upgrade your lawn with a custom irrigation solution that
                  delivers water at the right time and right place for your
                  grass, plants, and trees.
                </p>
              </div>
            </div>
          </Grid>
        </Container>

        {/* <h4 className="color-primary link-with-icon link-with-icon--primary text-center">
          <Link to="/services">
            Learn more
            <ArrowRightAltIcon />
          </Link>
        </h4> */}
      </section>

      <section className="section--good-company">
        <Container maxWidth="lg">
          <Grid container justify="center" alignItems="center" spacing={4}>
            <Grid item xs={12}>
              <img src={GreenThumb} className="good-company-thumb" />
            </Grid>
            <Grid item xs={12} md={12}>
              <h1 className="title text-center no-margin xs-text-left">
                You're in good company.
              </h1>
            </Grid>
          </Grid>

          <Grid
            container
            justify="space-around"
            className="good-company-text-container"
          >
            <Grid item xs={12} md={5} sm={5}>
              <h1 className="title title--medium no-margin color-secondary xs-text-left">
                <span className="color-primary">
                  We are fully licensed and insured.
                </span>{" "}
                We carry $2 million minimum liability insurance for all our
                projects. Your Lawn Doctor professionals are also vetted, and
                fully licensed.
              </h1>
            </Grid>
            <Grid item xs={12} md={5} sm={5}>
              <h1 className="title title--medium no-margin color-secondary xs-text-left">
                <span className="color-primary">
                  All our work is guaranteed.{" "}
                </span>{" "}
                We stand proudly behind our work and back all our landscape
                services, which includes our Healthy Lawn Guarantee™ for all sod
                installations.
              </h1>
            </Grid>
          </Grid>
        </Container>
      </section>

      <section className="section section--reviews">
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <img
                className="img-responsive review-area--tablet-image"
                src={reviewLandscapeGrandma}
              />

              <img
                className="img-responsive review-area--mobile-image"
                src={reviewPortraitGrandma}
              />
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12} md={7}></Grid>
            <Grid item xs={12} md={5}>
              <h1 className="title xs-text-left">Reviews</h1>

              <h2 className="title--medium xs-text-left">
                <span className="color-primary">9 out of 10 customers</span> who
                work with Lawn Doctors recommend us.
              </h2>

              <Grid container className="review-certifications">
                <Grid item md={6} sm={5} xs={6}>
                  <Box className="certification-item">
                    <Box>
                      <img
                        src={HomestarsLogo}
                        alt="Homestars"
                        className="homestars-icon"
                      />
                      <Box mt={1}>
                        <StarIcon />
                        <StarIcon />
                        <StarIcon />
                        <StarIcon />
                        <StarIcon />
                      </Box>
                    </Box>
                    <h4>10/10 rating</h4>
                  </Box>
                </Grid>
                <Grid item md={6} sm={5} xs={6}>
                  <Box className="certification-item">
                    <Box>
                      <img
                        src={GoogleLogo}
                        alt="Google"
                        className="google-icon"
                      />
                      <Box mt={1}>
                        <StarIcon />
                        <StarIcon />
                        <StarIcon />
                        <StarIcon />
                        <StarIcon />
                      </Box>
                    </Box>
                    <h4>Rated 5/5 stars</h4>
                  </Box>
                </Grid>
              </Grid>

              <div className="slider-container review-container">
                <div className="slide review-item">
                  <h1 className="review-item__review">
                    “It was quick, easy, and the price was exeedingly fair. They
                    treated me like I was their only client and I had that
                    one-on-one attention.”
                  </h1>
                  <h4 className="review-item__review-by">
                    Edith B
                    <br />
                    <span>Sod Installation in Kanata</span>
                  </h4>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>

        {/* <h4 className="color-primary link-with-icon link-with-icon--primary text-center">
          <Link to="/reviews">
            See all Reviews
            <ArrowRightAltIcon />
          </Link>
        </h4> */}
      </section>

      <section className="section section--faq">
        <Container maxWidth="lg">
          <Grid container justify="center" alignItems="center" spacing={4}>
            <Grid item xs={12} md={7}>
              <h1 className="title text-center no-margin">FAQs</h1>
            </Grid>
          </Grid>
        </Container>

        <Container maxWidth="lg">
          <Grid container justify="center" alignItems="center" spacing={4}>
            <Grid item xs={12} md={8}>
              <FAQList />
            </Grid>
          </Grid>
        </Container>

        <h4 className="color-primary link-with-icon link-with-icon--primary text-center">
          {/* <Link to="/faq">
            See all FAQs
            <ArrowRightAltIcon />
          </Link> */}
        </h4>
      </section>

      <section className="section section--cta">
        <Container>
          <Grid container spacing={4} justify="center">
            <Grid item xs={12} md={8}>
              <h1 className="title text-center">
                Let’s get started on your lawn.
              </h1>
              <p className="subtitle text-center">
                Answer a few questions about your landscaping goals and receive
                a free, all-inclusive estimate. There is no obligation.
              </p>
            </Grid>
          </Grid>
          <Grid container spacing={4} justify="center">
            <Grid item xs={12}>
              <Button
                onClick={() => props.history.push("/instant-price")}
                variant="contained"
                className="btn btn-lg btn-primary"
              >
                Start a Free Instant Quote
              </Button>
              <Box className="cta-bottom cta-bottom--big-area">
                <p className="cta-bottom__heading">
                  Call or chat with a lawn doctor
                </p>

                <Box className="cta-bottom__links">
                  <a href="tel:6134001265">
                    <CallIcon
                      style={{
                        marginRight: "8px",
                      }}
                    />
                    <span className="text">(613) 400-1265</span>
                  </a>

                  <a href="tel:6134001265">
                    <ForumIcon
                      style={{
                        marginRight: "8px",
                      }}
                    />
                    <span className="text">LIVE CHAT</span>
                  </a>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </section>

      <Footer />
    </>
  );
};
