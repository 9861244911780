import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, Container, Grid } from '@material-ui/core';
import { motion, AnimatePresence } from 'framer-motion';

import { ReactComponent as FrontYard } from '../../../assets/images/lawndoctors-illustrations-freeinstantprice-yard-front.svg';
import { ReactComponent as BackYard } from '../../../assets/images/lawndoctors-illustrations-freeinstantprice-yard-back.svg';
import { ReactComponent as WholeYard } from '../../../assets/images/lawndoctors-illustrations-freeinstantprice-yard-wholeyard.svg';

import CardRadioInput from '../../../components/CardRadioInput/CardRadioInput';

import { setTotalSteps } from '../../../slices/instantPriceSlice';

import goBack from '../goBack';
import goNext from '../goNext';

export default (props: any) => {
  const dispatch = useDispatch();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <h1 className="step-title title text-center">
            Where do you want to install sod?
          </h1>
        </Grid>
        <Grid item xs={12} md={8}>
          <p className="step-subtitle subtitle subtitle--medium text-center">
            Don't worry about obstacles like paths and patios, driveways or
            pools.
          </p>
        </Grid>
      </Grid>

      <div className="fancy-input-container fancy-input-container--center">
        <CardRadioInput
          imgSrc={FrontYard}
          imgIsSvg
          title="Front yard"
          selected={props.formik.values.installationLocation === 'frontYard'}
          onClick={() => {
            props.formik.setFieldValue('installationLocation', 'frontYard');
            goNext();
          }}
        />

        <CardRadioInput
          imgSrc={BackYard}
          imgIsSvg
          title="Back yard"
          selected={props.formik.values.installationLocation === 'backYard'}
          onClick={() => {
            props.formik.setFieldValue('installationLocation', 'backYard');
            dispatch(setTotalSteps(5));
            goNext();
          }}
        />

        <CardRadioInput
          imgSrc={WholeYard}
          imgIsSvg
          title="Whole yard"
          selected={props.formik.values.installationLocation === 'wholeYard'}
          onClick={() => {
            props.formik.setFieldValue('installationLocation', 'wholeYard');
            dispatch(setTotalSteps(5));
            goNext();
          }}
        />
      </div>

      <a className="instant-quote__previous-link" onClick={() => goBack()}>
        Previous step
      </a>
    </motion.div>
  );
};
