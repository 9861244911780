import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, Container, Grid, IconButton } from '@material-ui/core';
import CallIcon from '@material-ui/icons/Call';
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ForumIcon from '@material-ui/icons/Forum';
import EmailIcon from '@material-ui/icons/Email';

import { withRouter, Link, useLocation } from 'react-router-dom';

import InstantPriceProgress from '../InstantPriceProgress/InstantPriceProgress';
import { ReactComponent as Logo } from '../../assets/images/lawn-doctors-logo.svg';
import { ReactComponent as MobileLogo } from '../../assets/images/Lawn Doctors Logo (White).svg';

import { logout } from '../../slices/authSlice';
import { RootState } from '../../rootReducer';

import './Navigation.scss';
import { useMedia } from 'react-use';

const Navigation = (props: any) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isMobile = useMedia('(max-width: 1024px)');
  const progressBarVisible = useSelector(
    (state: RootState) => state.instantPrice.progressBarVisible
  );

  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated
  );

  const user = useSelector((state: RootState) => state.auth.user);

  const [mobileNavOpen, setMobileNavOpen] = React.useState(false);

  useEffect(() => {
    let lastknownScrollPosition = 0;
    let ticking = false;
    const mainNavigationEl = document.querySelector('.main-navigation');

    window.addEventListener('scroll', () => {
      lastknownScrollPosition = window.scrollY;

      if (!ticking) {
        window.requestAnimationFrame(() => {
          if (lastknownScrollPosition > 0) {
            mainNavigationEl?.classList.add('scrolled');
          } else {
            mainNavigationEl?.classList.remove('scrolled');
          }

          ticking = false;
        });

        ticking = true;
      }
    });
  }, []);

  const toggleMobileNav = () => {
    setMobileNavOpen(!mobileNavOpen);
    document.body.classList.toggle('mobile-nav-open');
  };

  const handleNavigate = (url: string) => {
    props.history.push(url);
    if (mobileNavOpen) {
      toggleMobileNav();
    }
  };

  const renderSignInLink = () => {
    if (isAuthenticated) {
      return (
        <a
          className="sign-out-link"
          style={{ marginLeft: '8px' }}
          onClick={() => {
            dispatch(logout());
            handleNavigate('/');
          }}
        >
          {' '}
          <ExitToAppIcon color="primary" style={{ marginRight: '8px' }} />
          <span className="text">Sign out</span>
        </a>
      );
    } else {
      return (
        <a
          onClick={() => {
            handleNavigate('/sign-in');
          }}
          className="sign-in-desktop"
        >
          <PersonIcon color="primary" style={{ marginRight: '8px' }} />
          <span className="text">Sign in</span>
        </a>
      );
    }
  };

  return (
    <>
      <nav
        className={`main-navigation ${location.pathname === '/instant-price'
            ? 'main-navigation--instant-price'
            : location.pathname === '/'
              ? 'main-navigation--landing'
              : ''
          }`}
      >
        <Container maxWidth="lg">
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="space-between"
          >
            <div className="main-navigation__left">
              <MobileLogo
                className="mobile-logo--white"
                onClick={() => handleNavigate('/')}
              />

              <Logo className="logo" onClick={() => handleNavigate('/')} />

              <div className="main-navigation__left__items">
                {/* <Link to="/about-us">About us</Link>
                <Link to="/how-we-work">Services</Link>
                <Link to="/financing">Financing</Link> */}
              </div>
            </div>
            <div className="main-navigation__right">
              <div className="main-navigation__right__items">
                <a
                  href="tel:6134001265"
                  className="mobile-navigation__phone-link"
                >
                  <CallIcon
                    style={{
                      marginRight: '8px',
                    }}
                  />
                  <span className="text">(613) 400-1265</span>
                </a>

                {/* <a
                  href="tel:6139542368"
                  className="mobile-navigation__phone-link"
                >
                  <ForumIcon
                    style={{
                      marginRight: '8px',
                    }}
                  />
                  <span className="text">LIVE CHAT</span>
                </a> */}

                <div className="mobile-navigation__signin-link-container no-margin-bottom desktop-nav">
                  {!isMobile && renderSignInLink()}
                </div>

                {props.location.pathname !== '/instant-price' &&
                  !isAuthenticated && (
                    // props.location.pathname !== '/' &&
                    <Link to="/instant-price" className="btn-link">
                      <Button className="btn btn-primary" variant="contained">
                        Get Started
                      </Button>
                    </Link>
                  )}
              </div>
              <Box className="main-navigation__burger">
                <IconButton onClick={toggleMobileNav} size="medium">
                  {mobileNavOpen ? <MenuOpenIcon /> : <MenuIcon />}
                </IconButton>
              </Box>
            </div>
          </Grid>
          {props.location.pathname === '/instant-price' &&
            progressBarVisible && <InstantPriceProgress />}
        </Container>
      </nav>
      <div className="mobile-navigation">
        <div></div>
        <nav>
          <a onClick={() => {
            // handleNavigate('/about-us')
          }} className="nav-link">
            About us
          </a>
          <a
            onClick={() => {
              //handleNavigate('/how-we-work')
            }}
            className="nav-link"
          >
            How we work
          </a>
          <a onClick={() => {
            handleNavigate('/financing')
          }} className="nav-link">
            Financing
          </a>

          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                mt={5}
              >
                {/* <CTA closeMenu /> */}
              </Box>
            </Grid>
          </Grid>
        </nav>

        <div className="mobile-navigation__signin-link-container">
          {/* {!isAuthenticated && (
            <a
              onClick={() => {
                console.log(isAuthenticated);
                if (isAuthenticated) {
                  //@ts-ignore
                  handleNavigate('/instant-price/' + user.instantPriceId);
                } else {
                  handleNavigate('/sign-in');
                }
              }}
            >
              <PersonIcon style={{ color: '#9b9b9b', marginRight: '8px' }} />
              Sign in
            </a>
          )} */}

          {/* {isAuthenticated ? (
            <a
              style={{ marginLeft: '8px' }}
              onClick={() => {
                dispatch(logout());
                handleNavigate('/');
              }}
            >
              Sign out
            </a>
          ) : (
            ''
          )} */}
        </div>
      </div>
    </>
  );
};

export default withRouter(Navigation);
