import React from 'react';

import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import DoneIcon from '@material-ui/icons/Done';

import goNext from '../../pages/InstantPrice/goNext';

import './CardRadioInput.scss';

export default (props: any) => {
  let classNames = 'card FancyInput FancyInput--Image FancyInput--Radio';

  if (props.fillFull) {
    classNames += ' FancyInput--FillFullOnSelected';
  }

  if (props.roofingImage) {
    classNames += ' FancyInput--RoofingImages';
  }

  if (props.roofingImageQuestionMark) {
    classNames += ' FancyInput--RoofingImagesQuestionMark';
  }

  if (props.forcedAirPowerImagesQuestionMark) {
    classNames += ' FancyInput--ForcedAirPowerImagesQuestionMark';
  }

  if (props.forcedAirPowerImages) {
    classNames += ' FancyInput--ForcedAirPowerImages';
  }

  if (props.selected) {
    classNames += ' FancyInput--selected';
  }

  return (
    <Card
      className={classNames}
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        }
      }}
    >
      {props.fillFull && <div className="FancyInput--FillFullBackground"></div>}
      {props.imgIsSvg && <props.imgSrc />}

      {props.imgSrc && !props.imgIsSvg && (
        <img className="img-initial" src={props.imgSrc} />
      )}

      {props.imgSrcSelected && !props.imgIsSvg && (
        <img className="img-selected" src={props.imgSrcSelected} />
      )}

      <div className="FancyInput__Text">
      { 
        // @ts-ignore
        }
        <Box>
          <h2 className="title--medium color-primary text-center">
            {props.title}
          </h2>
          {props.subtitle && (
            <h4 className="subtitle color-secondary text-uppercase">
              {props.subtitle}
            </h4>
          )}
        </Box>
      </div>
    </Card>
  );
};
