import React from 'react';
import { Box, Button, ButtonBase, Container, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import CallIcon from '@material-ui/icons/Call';
import ForumIcon from '@material-ui/icons/Forum';
import EmailIcon from '@material-ui/icons/Email';

import Logo from '../../assets/images/lawn-doctors-logo.svg';

import './Footer.scss';

export default () => {
  return (
    <footer>
      <Container maxWidth="lg">
        <Grid spacing={8}>
          <Grid item xs={12} md={4} sm={12}>
            <ButtonBase
              style={{
                width: '100%',
                margin: '0 auto',
              }}
              className="btn btn-secondary footer-btn"
            >
              <a href="mailto:hello@lawndoctors.ca">
                <EmailIcon color="primary" />

                <Box>
                  <p>Email us</p>
                  <p>hello@lawndoctors.ca</p>
                </Box>
              </a>
            </ButtonBase>
          </Grid>
          <Grid item xs={12} md={4} sm={12}>
            <ButtonBase
              style={{
                width: '100%',
                margin: '0 auto',
              }}
              className="btn btn-secondary footer-btn footer-btn--left"
            >
              <a href="tel:+16134001265">
                <CallIcon color="primary" />

                <Box>
                  <p>Call us</p>
                  <p>+1 (613) 400-1265</p>
                </Box>
              </a>
            </ButtonBase>
          </Grid>
          <Grid item xs={12} md={4} sm={12}>
            <ButtonBase
              style={{
                width: '100%',
                margin: '0 auto',
              }}
              className="btn btn-secondary footer-btn footer-btn--left"
            >
              <a href="#">
                <ForumIcon color="primary" />

                <Box>
                  <p>Chat with us</p>
                  <p>Online</p>
                </Box>
              </a>
            </ButtonBase>
          </Grid>
        </Grid>

        <div className="footer-btn-area"></div>

        <Grid spacing={8}>
          <Grid item xs={12} md={4}>
            <img src={Logo} />
          </Grid>

          <Grid item xs={12} md={4} sm={12}>
            <Box display="flex" className="footer-links-area">
              <ul className="footer-links">
                <li>
                  <a href="/about-us">About us</a>
                </li>
                {/* <li>
                  <a href="/services">Services</a>
                </li>
                <li>
                  <a href="/financing">Financing</a>
                </li> */}
              </ul>

              {/* <ul className="footer-links">
                <li>
                  <a href="/reviews">Reviews</a>
                </li>
                <li>
                  <a href="/faq">FAQ</a>
                </li>
                <li>
                  <a href="/account">Account</a>
                </li>
              </ul> */}
            </Box>
          </Grid>
        </Grid>

        <div style={{ marginTop: '75px' }}></div>

        <Grid>
          <Grid item xs={12}>
            <div className="divider"></div>

            <div className="copyright-area">
              <p>Lawn Doctors, {new Date().getFullYear()} &copy;</p>
              <p>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </p>
            </div>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};
