import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  Collapse,
  IconButton,
} from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';

import './FAQList.scss';

export default () => {
  const [activeListItem, setActiveListItem] = useState(1);

  const renderArrowIconBasedOnState = (listItemNumber: number) => {
    if (listItemNumber === activeListItem) {
      return (
        <IconButton
          style={{
            marginRight: '-8px',
          }}
        >
          <ExpandLess />
        </IconButton>
      );
    }

    return (
      <IconButton
        style={{
          marginRight: '-8px',
        }}
      >
        <ExpandMore />
      </IconButton>
    );
  };

  return (
    <ul className="faq-list">
      <div className="faq-list__item">
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          flexDirection="row"
          style={{ width: '100%' }}
          onClick={() => setActiveListItem(1)}
        >
          <h1
            className={`title title--medium faq-list__item__heading ${
              activeListItem === 1 && 'active'
            }`}
          >
            Is there any cost or obligation if I schedule a call with a Lawn
            Doctor or request an instant quote?
          </h1>

          {renderArrowIconBasedOnState(1)}
        </Box>

        <Collapse in={activeListItem === 1}>
          <p className="subtitle fw-m">
            Requesting an instant quote for your landscaping project is free,
            takes less than two minutes to complete, and comes with absolutely
            no obligations. To get started, answer a few questions about your
            landscaping goals and instantly receive a free, all-inclusive
            estimate.
          </p>
        </Collapse>
      </div>

      <div className="faq-list__item">
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          flexDirection="row"
          style={{ width: '100%' }}
          onClick={() => setActiveListItem(2)}
        >
          <h1
            className={`title title--medium faq-list__item__heading ${
              activeListItem === 2 && 'active'
            }`}
          >
            How can you give me a price without seeing my lawn?
          </h1>

          {renderArrowIconBasedOnState(2)}
        </Box>

        <Collapse in={activeListItem === 2}>
          <p className="subtitle fw-m">
            Requesting an instant quote for your landscaping project is free,
            takes less than two minutes to complete, and comes with absolutely
            no obligations. To get started, answer a few questions about your
            landscaping goals and instantly receive a free, all-inclusive
            estimate.
          </p>
        </Collapse>
      </div>

      <div className="faq-list__item">
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          flexDirection="row"
          style={{ width: '100%' }}
          onClick={() => setActiveListItem(3)}
        >
          <h1
            className={`title title--medium faq-list__item__heading ${
              activeListItem === 3 && 'active'
            }`}
          >
            What type of landscape services does Lawn Doctors offer?
          </h1>

          {renderArrowIconBasedOnState(3)}
        </Box>

        <Collapse in={activeListItem === 3}>
          <p className="subtitle fw-m">
            Requesting an instant quote for your landscaping project is free,
            takes less than two minutes to complete, and comes with absolutely
            no obligations. To get started, answer a few questions about your
            landscaping goals and instantly receive a free, all-inclusive
            estimate.
          </p>
        </Collapse>
      </div>
    </ul>
  );
};
