import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Avatar,
  Box,
  Button,
  Collapse,
  Container,
  Grid,
  IconButton,
  CircularProgress,
  Chip,
} from '@material-ui/core';
import CallIcon from '@material-ui/icons/Call';
import ForumIcon from '@material-ui/icons/Forum';
import EmailIcon from '@material-ui/icons/Email';

import CTA from '../../components/CTA/CTA';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { RootState } from '../../rootReducer';
import {
  fetchInstantPrice,
  fetchInstantPriceCalculation,
} from '../../slices/instantPriceSlice';
import InstantPrice from '../InstantPrice/InstantPrice';
import Footer from '../../components/Footer/Footer';

import step1HowWeWork from '../../assets/images/lawn-doctors-step-1.jpg';
import step2HowWeWork from '../../assets/images/lawn-doctors-step-2.jpg';
import step3HowWeWork from '../../assets/images/lawn-doctors-step-3.jpg';

import './Quote.scss';

// declare const fbq: any;

export default (props: any) => {
  const dispatch = useDispatch();

  const id = props.match.params.id;

  const instantPrice: any = useSelector(
    (state: RootState) => state.instantPrice.instantPrice
  );

  const instantPriceLoading = useSelector(
    (state: RootState) => state.instantPrice.getInstantPriceLoading
  );

  const instantPriceCalculationLoading = useSelector(
    (state: RootState) => state.instantPrice.getInstantPriceCalculationLoading
  );

  const instantPriceCalculation: any = useSelector(
    (state: RootState) => state.instantPrice.instantPriceCalculation
  );

  useEffect(() => {
    dispatch(fetchInstantPrice(id));
    dispatch(fetchInstantPriceCalculation(id));

    // fbq('track', 'ViewContent');
  }, []);

  const [breakdownCollapseOpen, setBreakdownCollapse] = React.useState(false);
  const [paymentMethod, setPaymentMethod] = React.useState('cash');

  if (instantPriceLoading) {
    return (
      <>
        <Container>
          <Box mt={16}>
            <CircularProgress size="large" />
          </Box>
        </Container>
      </>
    );
  }

  const renderCost = (costCategory: string) => {
    //@ts-ignore
    if (instantPrice?.serviceType === 'sodInstallation') {
      if (costCategory === 'construction') {
        //@ts-ignore
        return `$${instantPriceCalculation?.sidingMaterialInstallation.toLocaleString()}`;
      }

      //@ts-ignore
      return `$${instantPriceCalculation.sidingMaterialPerSqFt.toLocaleString()}`;
    }

    //roofing
    if (costCategory === 'construction') {
      //@ts-ignore
      const constructionTotal =
        //@ts-ignore
        instantPriceCalculation?.homeStoreys +
        //@ts-ignore
        instantPriceCalculation?.roofPitchType;

      //@ts-ignore
      return `$${constructionTotal.toLocaleString()}`;
    }

    //@ts-ignore
    return `$${instantPriceCalculation?.roofMaterial.toLocaleString()}`;
  };

  const renderSubTotalEstimate = (inMonths = false, complete = false) => {
    if (instantPrice?.serviceType === 'siding') {
      if ((paymentMethod === 'cash' && !inMonths) || complete) {
        const total =
          //@ts-ignore
          instantPriceCalculation.sidingMaterialPerSqFt +
          //@ts-ignore
          instantPriceCalculation.sidingMaterialInstallation;

        return `$${total.toLocaleString()}`;
      } else {
        const total =
          //@ts-ignore
          (instantPriceCalculation.sidingMaterialPerSqFt +
            //@ts-ignore
            instantPriceCalculation.sidingMaterialInstallation) /
          60;

        return `$${total.toLocaleString()}/month`;
      }
    } else {
      if ((paymentMethod === 'cash' && !inMonths) || complete) {
        const total =
          //@ts-ignore
          instantPriceCalculation?.homeStoreys +
          //@ts-ignore
          instantPriceCalculation?.roofPitchType +
          //@ts-ignore
          instantPriceCalculation?.roofMaterial;
        //@ts-ignore

        return `$${total.toLocaleString()}`;
      } else {
        const total =
          //@ts-ignore
          (instantPriceCalculation?.homeStoreys +
            //@ts-ignore
            instantPriceCalculation?.roofPitchType +
            //@ts-ignore
            instantPriceCalculation?.roofMaterial) /
          60;

        return `$${total.toLocaleString()}/month`;
      }
    }
  };

  const getNiceName = (serviceName: string) => {
    switch (serviceName) {
      case 'customPaving':
        return 'Custom Paving';

      case 'sodInstallation':
        return 'Sod Installation';

      case 'lawnIrrigation':
        return 'Lawn Irrigation';

      case 'installationLocation':
        return 'Installation';

      case 'frontYard':
        return 'Front yard';

      case 'backYard':
        return 'Back yard';

      case 'wholeYard':
        return 'Whole yard';

      case 'backyardAccessible':
        return 'Accessibility';

      case 'easyAccess':
        return 'Easy access';

      case 'hardAccess':
        return 'Hard access';

      case 'propertySize':
        return 'Property size';

      case 'townhome':
        return 'Townhome';

      case 'singleFamily':
        return 'Single family';

      default:
        return '';
    }
  };

  if (!instantPriceLoading && instantPrice !== null) {
    return (
      <>
        <Container>
          <Box mt={16}>
            <section className="section section--estimate">
              <Grid container justify="center">
                <Grid item xs={12} md={10}>
                  <h1 className="title text-center mb-0">
                    Welcome, {instantPrice.firstName}!
                  </h1>
                </Grid>
                <Grid item xs={12} md={6}>
                  <p className="subtitle subtitle subtitle--medium text-center mt-2">
                    Thank you for submitting your project details, we’re excited
                    to work with you.
                  </p>
                </Grid>
              </Grid>

              <Grid container style={{ marginTop: '20px' }}>
                <Grid item xs={12}>
                  <Box textAlign="center" mb={5}>
                    <Avatar
                      className="quote-avatar"
                      style={{
                        margin: '0 auto 16px',
                      }}
                      src={'/images/lawn-doctors-mustafa-sakr.png'}
                    />
                    <p
                      className="f-sans-serif subtitle--medium"
                      style={{ marginBottom: '0' }}
                    >
                      Mustafa Sakr
                    </p>
                    <p
                      className="f-sans-serif color-secondary text-uppercase"
                      style={{
                        marginTop: '5px',
                        fontSize: '14px',
                        lineHeight: '19px',
                        fontWeight: 'normal',
                        // color: '#9B9B9B',
                      }}
                    >
                      Owner, Lawn Doctor
                    </p>
                    <Box className="cta-bottom cta-bottom--big-area cta-bottom--dark">
                      <p className="cta-bottom__heading">
                        Call or chat with Mustafa
                      </p>

                      <Box className="cta-bottom__links">
                        <a href="tel:6134001265">
                          <CallIcon
                            style={{
                              marginRight: '8px',
                            }}
                          />
                          <span className="text color-primary">Call</span>
                        </a>

                        <a href="mailto:mustafa@lawndoctors.ca">
                          <EmailIcon
                            style={{
                              marginRight: '8px',
                            }}
                          />
                          <span className="text color-primary">Email</span>
                        </a>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>

              <Grid container justify="center">
                <Grid item xs={12}>
                  <h1 className="title title--medium text-center mb-1">
                    Your estimate is:
                  </h1>
                  {!instantPriceCalculationLoading && (
                    <>
                      <h1 className="title text-center mt-0">
                        {/* {renderSubTotalEstimate(false, true)} */}
                        <span className="color-primary">
                          ${instantPriceCalculation.range1.toLocaleString()} - $
                          {instantPriceCalculation.range2.toLocaleString()}
                        </span>
                      </h1>
                      {/* <h1 className="title text-center mt-0"></h1> */}
                      <p
                        className="cta-bottom__heading text-center color-secondary-2"
                        style={{ marginTop: '0', marginBottom: '1em' }}
                      >
                        Or{' '}
                        {
                          //renderSubTotalEstimate(true)
                        }
                        $
                        {`${(
                          instantPriceCalculation.range1 / 60
                        ).toLocaleString()}-$${(
                          instantPriceCalculation.range2 / 60
                        ).toLocaleString()}`}{' '}
                        at 2.45% APR* for 60 months
                      </p>
                    </>
                  )}
                </Grid>
              </Grid>

              <Grid container spacing={2} alignItems="center" justify="center">
                <Grid item xs={12} md={6}>
                  <Box mt={6} display="flex" justifyContent="center">
                    <a
                      style={{ textDecoration: 'none' }}
                      href={`mailto:mustafa@lawndoctors.ca`}
                    >
                      <Button className="btn btn-primary" variant="contained">
                        Schedule Site Visit
                      </Button>
                    </a>
                  </Box>
                </Grid>
              </Grid>
            </section>

            <Grid container justify="center">
              {instantPriceCalculationLoading && <CircularProgress />}

              {!instantPriceCalculationLoading && (
                <Grid item xs={12} md={8} sm={8}>
                  <Box
                    width="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    onClick={() => setBreakdownCollapse(!breakdownCollapseOpen)}
                    mt={6}
                    style={{ cursor: 'pointer' }}
                  >
                    <h5
                      className="title title--medium"
                      style={{
                        marginBottom: '0',
                        marginTop: '0',
                      }}
                    >
                      See breakdown
                    </h5>

                    <IconButton>
                      {breakdownCollapseOpen ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  </Box>
                  <Collapse
                    in={breakdownCollapseOpen}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Box
                      width="100%"
                      mt={2}
                      mb={2}
                      className="breakdown-payment-method-switch"
                    >
                      <Chip
                        className={`${
                          paymentMethod === 'cash' ? 'selected' : ''
                        }`}
                        label="Cash"
                        onClick={() => setPaymentMethod('cash')}
                        style={{ marginRight: '10px' }}
                      />
                      <Chip
                        className={`${
                          paymentMethod === 'financing' ? 'selected' : ''
                        }`}
                        label="Financing"
                        onClick={() => setPaymentMethod('financing')}
                      />
                    </Box>

                    <ul className="quote-breakdown__list">
                      <li className="quote-breakdown__list__item">
                        <span>Service</span>
                        <span>{getNiceName(instantPrice.serviceType)}</span>
                      </li>
                      <li className="quote-breakdown__list__item">
                        <span>Property size</span>
                        <span>{getNiceName(instantPrice.propertySize)}</span>
                      </li>
                      <li className="quote-breakdown__list__item">
                        <span>Coverage</span>
                        <span>
                          {getNiceName(instantPrice.installationLocation)}
                        </span>
                      </li>
                      <li className="quote-breakdown__list__item">
                        <span>Accessibility</span>
                        <span>{getNiceName(instantPrice.accessibility)}</span>
                      </li>
                      <li className="quote-breakdown__list__item">
                        <span className="color-black">
                          {paymentMethod === 'cash'
                            ? 'Subtotal estimate'
                            : 'Monthly financing estimate'}
                        </span>
                        <span className="color-black">
                          {paymentMethod === 'cash'
                            ? `$${instantPriceCalculation.range1.toLocaleString()}-$${instantPriceCalculation.range2.toLocaleString()}`
                            : `$${(
                                instantPriceCalculation.range1 / 60
                              ).toLocaleString()}-$${(
                                instantPriceCalculation.range2 / 60
                              ).toLocaleString()}`}
                        </span>
                        {/* <span>{renderSubTotalEstimate()}</span> */}
                      </li>
                    </ul>
                  </Collapse>
                </Grid>
              )}

              <Grid item xs={12} md={8}>
                <p
                  className="disclaimer text-align-left"
                  style={{
                    fontSize: '12px',
                    lineHeight: '17px',
                    color: '#9b9b9b',
                    marginTop: '50px',
                    textAlign: 'justify',
                  }}
                >
                  This estimate do not include applicable taxes. The information
                  presented on this website is based on data available at the
                  time. This estimate is not guaranteed nor considered your
                  final price and does not account for unpredicted conditions.
                  Although we are able to supply you with an instant price so
                  quickly, your licensed and dedicated Lawn Doctor professional
                  must conduct a site visit to confirm a few details about your
                  property. At which point, you will receive a free,
                  all-inclusive estimate with a breakdown of our costs.
                </p>
              </Grid>
            </Grid>
          </Box>
        </Container>
        <div className="section-what-next">
          <Container>
            <Grid container spacing={4} justify="center">
              <Grid item xs={12} md={6}>
                <h1 className="title text-center" style={{ marginBottom: '0' }}>
                  What’s next?
                </h1>
              </Grid>
            </Grid>
          </Container>

          <Container maxWidth="lg" className="service-item service-item--two">
            <Grid
              container
              justify="space-around"
              alignItems="center"
              spacing={4}
            >
              <Grid item xs={12} sm={6} md={5}>
                <h1 className="title title--medium no-margin color-secondary">
                  <span className="color-primary">
                    Review and accept your free, all-inclusive estimate.
                  </span>{' '}
                  A dedicated Lawn Doctor will meet with you to confirm your
                  estimate and walk you through our process.
                </h1>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <img src={step2HowWeWork} className="img-responsive" alt="" />
              </Grid>
            </Grid>
          </Container>

          <div className="divider divider--services"></div>

          <Container maxWidth="lg" className="service-item service-item--three">
            <Grid
              container
              justify="space-around"
              alignItems="center"
              spacing={4}
            >
              <Grid item xs={12} sm={6} md={6}>
                <img src={step3HowWeWork} className="img-responsive" alt="" />
              </Grid>
              <Grid item xs={12} sm={6} md={5}>
                <h1 className="title title--medium no-margin color-secondary">
                  <span className="color-primary">
                    Your Lawn Doctor professionals take care of the rest.
                  </span>{' '}
                  Our fully licensed and reliable team will finish your project
                  on-time and on-budget.
                </h1>
              </Grid>
            </Grid>
          </Container>

          <Grid container spacing={4} justify="center">
            <Grid item xs={12}>
              <Box
                className="cta-bottom cta-bottom--big-area"
                display="flex"
                justifyContent="center"
              >
                <a
                  style={{
                    textDecoration: 'none',
                    textAlign: 'center',
                    display: 'inline-block',
                    margin: '0 auto',
                  }}
                  href={`mailto:mustafa@lawndoctors.ca`}
                >
                  <Button className="btn btn-primary" variant="contained">
                    Schedule Site Visit
                  </Button>
                </a>
                <p className="cta-bottom__heading text-center">
                  Call or chat with a lawn doctor
                </p>

                <Box className="cta-bottom__links">
                  <a href="tel:6134001265">
                    <CallIcon
                      style={{
                        marginRight: '8px',
                      }}
                    />
                    <span className="text color-primary">(613) 400-1265</span>
                  </a>

                  <a href="tel:6134001265">
                    <ForumIcon
                      style={{
                        marginRight: '8px',
                      }}
                    />
                    <span className="text color-primary">LIVE CHAT</span>
                  </a>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>

        <Footer />
      </>
    );
  }
};
