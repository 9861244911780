import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Container, Grid, LinearProgress } from '@material-ui/core';
import { motion, AnimatePresence } from 'framer-motion';
import { useFormik, FieldArray } from 'formik';
import * as Yup from 'yup';
import { Helmet } from 'react-helmet';

import Navigation from '../../components/Navigation/Navigation';

import Step1 from './Step1';
import Step2 from './Step2';

import Step3Sodding from './Sodding/Step3';
import Step31Sodding from './Sodding/Step31';

import Step5 from './Step5';
import Step6 from './Step6';

import { RootState } from '../../rootReducer';

import {
  hideProgressBar,
  createInstantPrice,
  setServiceType,
} from '../../slices/instantPriceSlice';

import './InstantPrice.scss';
import InstantPriceSmallProgress from '../../components/InstantPriceSmallProgress/InstantPriceSmallProgress';

export default (props: any) => {
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      firstName: '',
      phoneNumber: '',
      emailAddress: '',
      address: '',
      timeline: '',

      serviceType: '',

      //sodding
      propertySize: '',
      installationLocation: '',
      backyardAccessible: '',
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required(),
      emailAddress: Yup.string().email().required(),
      phoneNumber: Yup.string()
        .matches(/^[(][0-9]{3}[)][ ][0-9]{3}-[0-9]{4}$/)
        .required(),
      address: Yup.string().required(),
      timeline: Yup.string(),

      serviceType: Yup.string()
        .oneOf(['sodInstallation', 'customPaving', 'lawnIrrigation'])
        .required(),

      propertySize: Yup.string(),
      installationLocation: Yup.string(),
      backyardAccessible: Yup.string(),

      // hvacServices: Yup.array().when('serviceType', {
      //   is: (val: string) => val === 'hvac',
      //   then: Yup.array().required(),
      //   otherwise: Yup.array(),
      // }),
    }),

    onSubmit: (values, { setSubmitting }) => {
      // alert(JSON.stringify(values, null, 2));
      setSubmitting(true);
      console.log(values);

      try {
        dispatch(createInstantPrice(values, setSubmitting));
      } catch (err) {
        console.log(err);
      } finally {
      }
    },
  });

  const step = useSelector((state: RootState) => state.instantPrice.step);
  const totalSteps = useSelector(
    (state: RootState) => state.instantPrice.totalSteps
  );
  const subStep = useSelector((state: RootState) => state.instantPrice.subStep);

  useEffect(() => {
    window?.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (formik.values.serviceType !== 'hvac') {
      if (step === 5) {
        setTimeout(() => {
          dispatch(hideProgressBar());
        }, 500);
      }
    }
  }, [step]);

  const renderStepContent = () => {
    console.log(step);
    console.log(subStep);

    if (step === 1) {
      return <Step1 formik={formik} />;
    }

    if (formik.values.serviceType == 'sodInstallation') {
      if (step === 2) {
        return <Step2 formik={formik} />;
      }

      if (step === 3) {
        return <Step3Sodding formik={formik} />;
      }

      if (formik.values.installationLocation !== 'frontYard') {
        if (step === 4) {
          return <Step31Sodding formik={formik} />;
        }

        if (step === 5) {
          return <Step5 formik={formik} />;
        }

        if (step === 6) {
          return <Step6 formik={formik} />;
        }
      } else {
        if (step === 4) {
          return <Step5 formik={formik} />;
        }

        if (step === 5) {
          return <Step6 formik={formik} />;
        }
      }
    }

    if (formik.values.serviceType !== 'sodInstallation') {
      if (step === 2) {
        return <Step5 formik={formik} />;
      }

      if (step === 3) {
        return <Step6 formik={formik} />;
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Instant Price – Lawn Doctors</title>
      </Helmet>

      {console.log(formik.errors)}

      <Navigation instantPrice />

      <Container maxWidth="lg" style={{ paddingBottom: '32px' }}>
        <form
          className="instant-price-form"
          onSubmit={formik.handleSubmit}
          autoComplete="off"
        >
          {step <= totalSteps && <InstantPriceSmallProgress />}
          <AnimatePresence>{renderStepContent()}</AnimatePresence>
        </form>
      </Container>
    </>
  );
};
