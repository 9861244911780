import React, { useState } from 'react';
import { Box, Button, Container, Grid } from '@material-ui/core';
import { motion, AnimatePresence } from 'framer-motion';

import { ReactComponent as EasyAccess } from '../../../assets/images/lawndoctors-freeinstantprice-access-easyaccess.svg';
import { ReactComponent as HardAccess } from '../../../assets/images/lawndoctors-freeinstantprice-access-hardaccess.svg';

import CardRadioInput from '../../../components/CardRadioInput/CardRadioInput';
import goBack from '../goBack';
import goNext from '../goNext';

export default (props: any) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <h1 className="step-title title text-center">
            Is your backyard accessible for machinery?
          </h1>
        </Grid>
        <Grid item xs={12} md={8}>
          <p className="step-subtitle subtitle subtitle--medium text-center">
            We need to know if our machinery can access your backyard without
            any obstacles, like if there are any air conditioning units in the
            way.
          </p>
        </Grid>
      </Grid>

      <div className="fancy-input-container fancy-input-container--center">
        <CardRadioInput
          imgSrc={EasyAccess}
          imgIsSvg
          title="Accessible"
          subtitle="At least 3.5 ft of clearance"
          selected={props.formik.values.backyardAccessible === 'easyAccess'}
          onClick={() => {
            props.formik.setFieldValue('backyardAccessible', 'easyAccess');
            goNext();
          }}
        />

        <CardRadioInput
          imgSrc={HardAccess}
          imgIsSvg
          title="Hard access"
          subtitle="Less than 3.5 ft of clearance"
          selected={props.formik.values.backyardAccessible === 'hardAccess'}
          onClick={() => {
            props.formik.setFieldValue('backyardAccessible', 'hardAccess');
            goNext();
          }}
        />
      </div>

      <a className="instant-quote__previous-link" onClick={() => goBack()}>
        Previous step
      </a>
    </motion.div>
  );
};
