import React from 'react';
import { Box, Grid, CircularProgress } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import ReplyIcon from '@material-ui/icons/Reply';

export default (props: any) => {
  const [resendEmailLoading, setResendEmailLoading] = React.useState(false);

  const handleResendEmail = () => {
    if (resendEmailLoading) {
      return;
    }

    setResendEmailLoading(true);

    setTimeout(() => {
      setResendEmailLoading(false);
    }, 3000);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, delay: 1 }}
    >
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <h1 className="step-title title text-center">
            {props.formik.values.serviceType !== 'sodInstallation'
              ? `Thanks, ${props.formik.values.firstName}`
              : `${props.formik.values.firstName}, check your inbox.`}
          </h1>
        </Grid>
        <Grid item xs={12} md={10}>
          {props.formik.values.serviceType !== 'sodInstallation' ? (
            <>
              <p
                className="step-subtitle subtitle subtitle--medium"
                style={{
                  marginBottom: '1em',
                }}
              >
                Unfortunately, we cannot provide you with an instant price. Your
                licensed and dedicated Lawn Doctor professional will contact you
                to discuss the details of your landscaping project and to
                schedule a time to see your property.
              </p>

              <p
                className="step-subtitle subtitle subtitle--medium"
                style={{
                  marginBottom: '1em',
                }}
              >
                We want to remind you that there are no obligations tied to this
                consultation - this is an opportunity for us to meet and for you
                to ask questions. There are no up-front costs neither and the
                quote is entirely free.
              </p>

              <p
                className="step-subtitle subtitle subtitle--medium"
                style={{
                  marginBottom: '1em',
                }}
              >
                We look forward to working with you.
              </p>
            </>
          ) : (
            <>
              <p
                className="step-subtitle subtitle subtitle--medium"
                style={{
                  marginBottom: '1em',
                }}
              >
                Your licensed and dedicated Lawn Doctor professional will
                contact you to discuss the details of your landscape project and
                to schedule a time to see your property.
              </p>

              <p
                className="step-subtitle subtitle subtitle--medium"
                style={{
                  marginBottom: '1em',
                }}
              >
                We want to remind you that there are no obligations tied to this
                consultation - this is an opportunity for us to meet and for you
                to ask questions. There are no up-front costs neither and the
                quote is entirely free.
              </p>

              <p
                className="step-subtitle subtitle subtitle--medium"
                style={{
                  marginBottom: '1em',
                }}
              >
                We look forward to working with you.
              </p>

              <p
                className="step-subtitle subtitle subtitle--medium"
                style={{
                  marginBottom: '1em',
                }}
              >
                We just sent you an email with a link to access your free
                estimate by email.
              </p>
            </>
          )}
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} md={6}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            mt={4}
          >
            {/* <p
                style={{
                  fontWeight: 'normal',
                  fontSize: '14px',
                  lineHeight: '19px',
                  color: '#9b9b9b',
                  marginBottom: '8px',
                }}
              >
                Or continue with Facebook
              </p>

              <Button
                className="btn btn-primary btn-facebook"
                variant="contained"
                type="submit"
              >
                <FacebookLogo /> Continue with Facebook
              </Button> */}

            {/* {props.formik.values.serviceType !== 'hvac' && (
              <a
                className="instant-quote__previous-link instant-quote__previous-link--fixed-bottom resend-email-link"
                onClick={handleResendEmail}
              >
                <span style={{ opacity: resendEmailLoading ? 0 : 1 }}>
                  Resend email
                  <ReplyIcon />
                </span>
                <CircularProgress
                  size="24"
                  style={{
                    position: 'absolute',
                    opacity: resendEmailLoading ? 1 : 0,
                    margin: 0,
                    padding: 0,
                  }}
                />
              </a>
            )} */}
          </Box>
        </Grid>
      </Grid>
    </motion.div>
  );
};
