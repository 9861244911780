import React, { useState } from 'react';
import { Box, Button, Container, Grid } from '@material-ui/core';
import { motion, AnimatePresence } from 'framer-motion';

import CardRadioInput from '../../components/CardRadioInput/CardRadioInput';

import { ReactComponent as LotSizeCity } from '../../assets/images/lawndoctors-illustrations-freeinstantprice-lotsize-city.svg';
import { ReactComponent as LotSizeCountry } from '../../assets/images/lawndoctors-illustrations-freeinstantprice-lotsize-country.svg';

import goNext from './goNext';
import goBack from './goBack';

import Footer from '../../components/Footer/Footer';

export default (props: any) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Box>
        <Grid container justify="center">
          <Grid item xs={12} md={10}>
            <h1 className="step-title title text-center">
              What is the size of your property?
            </h1>
          </Grid>
          <Grid item xs={12} md={8}>
            <p className="step-subtitle subtitle subtitle--medium text-center">
              City lots in Ottawa are typically 20 feet wide for town homes and
              up to 50 feet wide for single family homes.
            </p>
          </Grid>
        </Grid>

        <div className="fancy-input-container fancy-input-container--center">
          <CardRadioInput
            imgSrc={LotSizeCity}
            imgIsSvg
            title="Townhome"
            subtitle="20 ft. to 30 ft. property"
            selected={props.formik.values.propertySize === 'townhome'}
            onClick={() => {
              props.formik.setFieldValue('propertySize', 'townhome');
              goNext();
            }}
          />

          <CardRadioInput
            imgSrc={LotSizeCountry}
            imgIsSvg
            title="Single family"
            subtitle="30 ft. to 60 ft. property"
            selected={props.formik.values.propertySize === 'singleFamily'}
            onClick={() => {
              props.formik.setFieldValue('propertySize', 'singleFamily');
              goNext();
            }}
          />
        </div>

        <a className="instant-quote__previous-link" onClick={() => goBack()}>
          Previous step
        </a>
      </Box>
    </motion.div>
  );
};
