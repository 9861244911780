import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';

import './InstantPriceProgress.scss';

export default () => {
  const step = useSelector((state: RootState) => state.instantPrice.step);
  const totalSteps = useSelector(
    (state: RootState) => state.instantPrice.totalSteps
  );

  const getProgress = () => {
    return (step / totalSteps) * 100 + '%';
  };

  return (
    <div className="instant-quote__progress">
      <div
        className="instant-quote__progress__filled"
        style={{ width: getProgress() }}
      ></div>
    </div>
  );
};
