import React from 'react';
import {
  Avatar,
  Box,
  Button,
  Grid,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import MaskedInput from 'react-text-mask';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import CallIcon from '@material-ui/icons/Call';

import goBack from './goBack';

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

function TextMaskCustom(props: TextMaskCustomProps) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
    />
  );
}

export default (props: any) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Box>
        <Grid container justify="center">
          <Grid item xs={12} md={10}>
            <h1 className="step-title title text-center">
              Just a few more details.
            </h1>
          </Grid>
          <Grid item xs={12} md={8}>
            <p className="step-subtitle subtitle subtitle--medium text-center">
              Tell us where we can get in touch with you to discuss your project
              and provide you with a free, all-inclusive estimate.
            </p>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          alignItems="center"
          justify="center"
          style={{ marginTop: '50px' }}
          wrap="wrap"
        >
          <Grid item xs={12} md={5} sm={6}>
            <TextField
              variant="outlined"
              fullWidth
              placeholder="First name"
              name="firstName"
              className="field"
              onBlur={props.formik.handleBlur}
              onChange={props.formik.handleChange}
              error={
                props.formik.errors.firstName && props.formik.touched.firstName
              }
            />
          </Grid>
          <Grid item xs={12} md={5} sm={6}>
            <TextField
              variant="outlined"
              fullWidth
              placeholder="Email address"
              name="emailAddress"
              className="field"
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              error={
                props.formik.errors.emailAddress &&
                props.formik.touched.emailAddress
              }
            />
          </Grid>

          <Grid item xs={12} md={5} sm={6}>
            <TextField
              variant="outlined"
              fullWidth
              placeholder="Phone number"
              name="phoneNumber"
              className="field"
              onBlur={props.formik.handleBlur}
              onChange={props.formik.handleChange}
              error={
                props.formik.errors.phoneNumber &&
                props.formik.touched.phoneNumber
              }
              InputProps={{
                inputComponent: TextMaskCustom as any,
              }}
            />
          </Grid>
          <Grid item xs={12} md={5} sm={6}>
            <TextField
              variant="outlined"
              fullWidth
              placeholder="Address"
              name="address"
              className="field"
              onBlur={props.formik.handleBlur}
              onChange={props.formik.handleChange}
              error={
                props.formik.errors.address && props.formik.touched.address
              }
            />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          alignItems="center"
          justify="center"
          wrap="wrap"
        >
          <Grid item xs={12} md={5} sm={6}>
            <TextField
              variant="outlined"
              style={{ marginTop: '8px' }}
              fullWidth
              placeholder="Timeline"
              name="timeline"
              className="field"
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              error={
                props.formik.errors.timeline && props.formik.touched.timeline
              }
            />
          </Grid>
          <Grid item xs={12} md={5} sm={6}>
            <p></p>
          </Grid>
        </Grid>

        <Grid container spacing={2} alignItems="center" justify="center">
          <Grid item xs={12} md={10}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              mt={5}
            >
              <Button
                className="btn btn-primary"
                variant="contained"
                type="submit"
                disabled={props.formik.isSubmitting}
              >
                <CircularProgress
                  style={{ opacity: props.formik.isSubmitting ? 1 : 0 }}
                  size="20px"
                  color="secondary"
                />
                <span style={{ opacity: props.formik.isSubmitting ? 0 : 1 }}>
                  {props.formik.values.serviceType != 'sodInstallation'
                    ? 'Request a free quote'
                    : 'See my Instant Price'}
                </span>
              </Button>
              <a
                className="instant-quote__previous-link"
                style={{
                  position: 'relative',
                  bottom: 0,
                  marginTop: '16px',
                  marginBottom: '48px',
                }}
                onClick={() => goBack()}
              >
                Previous step
              </a>
              <p
                className="text-align-left disclaimer"
                style={{
                  paddingLeft: '32px',
                  paddingRight: '32px',
                  textAlign: 'justify',
                }}
              >
                Your contact information is protected by our{' '}
                <Link
                  to="/privacy-policy"
                  style={{ textDecoration: 'underline', color: 'inherit' }}
                >
                  Privacy Policy
                </Link>{' '}
                and is not shared with any third parties. By pressing “See my
                Instant Price” you are consenting to receive marketing emails,
                calls, and texts from Lawn Doctors and Lawn Doctors only for the
                sole purpose of following up with you on your estimate and to
                market future products or services, including by automated
                means. You may out-out at any time and consent is not a
                condition of a sale. Our instant price is not guaranteed. The
                price you are receiving does not account for additional grading,
                difficulty in accessing your lawn, and any other unpredicted
                conditions. Although we are able to supply you with an instant
                price so quickly, a licensed and dedicated Lawn Doctor
                professional needs to visit your property to account for any
                unpredicted conditions. At which point, you will receive a
                final, fixed-price price in writing.
              </p>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </motion.div>
  );
};
