import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';

import './InstantPriceSmallProgress.scss';

export default (props: any) => {
  const step = useSelector((state: RootState) => state.instantPrice.step);
  const totalSteps = useSelector(
    (state: RootState) => state.instantPrice.totalSteps
  );

  const getProgress = () => {
    return (step / totalSteps) * 100 + '%';
  };

  return (
    <div className="instant-price-small-progress-container">
      <div className="instant-price-small-progress">
        <div
          className="instant-price-small-progress__filled"
          style={{ width: getProgress() }}
        ></div>
      </div>
      <h3>
        Step {step} of {totalSteps}
      </h3>
    </div>
  );
};
